<template>
  <lab-task>
    <lab-gas-laws-part2 />
  </lab-task>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabGasLawsPart2 from '../simulations/LabGasLawsPart2.vue';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'GasLawsPart2',
  components: {LabGasLawsPart2, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
